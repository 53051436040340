
import Navbar from "./component/Navbar";
import Main from "./component/arithmetic/Main";



function App() {
  return (
    <div className="App">

       <Navbar/>
       <Main/>
   
    </div>
  );
}

export default App;
